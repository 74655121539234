import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserManager } from 'oidc-client';

import { ReturnUrlService } from './return-url.service';

@Injectable({
    providedIn: 'root'
})
export class SignInService {
    constructor(
        private userManager: UserManager,
        private returnUrlService: ReturnUrlService,
        private router: Router
    ) { }

    public handleSignin(url: string): Promise<any> {
        if (url.startsWith('/sign-in-oidc')) {
            return this.userManager.signinRedirectCallback().then(() => {
                const returnUrl = this.returnUrlService.url;
                this.returnUrlService.clear();

                if (returnUrl) {
                    this.router.navigateByUrl(returnUrl);
                } else {
                    this.router.navigateByUrl('/');
                }
            });
        }

        if (url.startsWith('/sign-in')) {
            return this.userManager.signinRedirect();
        }

        return Promise.reject('Invalid url provided');
    }
}
