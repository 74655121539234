import { Provider } from '@angular/core';
import { environment } from '../../environments/environment';
import { Log, UserManager, WebStorageStateStore } from 'oidc-client';

if (!environment.production) {
    Log.level = Log.DEBUG;
    Log.logger = console;
}

if (window.localStorage.getItem('oidcLogLevel') === 'debug') {
    Log.level = Log.DEBUG;
    console.log('Debug logging for User Manager enabled');
}

export function userManagerFactory(): UserManager {
    const authenticationSettings = environment.authenticationSettings;
    const shouldMonitorSession = window.localStorage.getItem('shouldMonitorSession');
    let monitorSession = true;
    if (shouldMonitorSession !== null && shouldMonitorSession !== undefined) {
        monitorSession = shouldMonitorSession === 'true';
    }
    const userManager = new UserManager({
        authority: authenticationSettings.authorityUri,
        client_id: authenticationSettings.clientId,
        redirect_uri: authenticationSettings.redirectUri,
        post_logout_redirect_uri: authenticationSettings.postLogoutRedirectUri,
        silent_redirect_uri: authenticationSettings.silentRedirectUri,
        scope: authenticationSettings.scopes,
        response_type: authenticationSettings.responseTypes,
        automaticSilentRenew: /^true$/i.test(authenticationSettings.enableAutomaticSilentRenew),
        userStore: new WebStorageStateStore({ store: localStorage }),
        loadUserInfo: true,
        monitorSession: monitorSession,
    });

    userManager.clearStaleState();

    return userManager;
}

export const USER_MANAGER_PROVIDER: Provider = {
    provide: UserManager,
    useFactory: userManagerFactory,
    deps: [],
};
