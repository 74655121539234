import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from './auth/user.service';

@Component({
  selector: 'app-menu',
  template: `<nav class="navbar navbar-expand-sm navbar-light bg-light">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="basics/home">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="basics/public">Public</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="basics/admin1">
          <span *ngIf="(isAuthenticated$ | async) === false">🔒</span>
          Admin-1
        </a>
      </li>
    </ul>
    <button class="btn btn-sm btn-default" (click)="login()" *ngIf="(isAuthenticated$ | async) === false">Log in</button>
    <span *ngIf="isAuthenticated$ | async" id="email">{{email}}</span>
    <button *ngIf="isAuthenticated$ | async" href="#" (click)="logout()" class="btn btn-link">(log out)</button>
  </nav>`,
})
export class AppMenuComponent {
  isAuthenticated$: Observable<boolean>;

  constructor(private userService: UserService) {
    this.isAuthenticated$ = userService.isAuthenticated$;
  }

  login() {
    this.userService.signInRedirectIfRequired();
  }

  logout() {
    this.userService.signOut();
  }

  get email(): string {
    return this.userService.identityClaims
    ? (this.userService.identityClaims as any)['email']
    : '-';
  }
}
