import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

/**
 * This component is for debug purposes and needs to be manually navigated to.
 */
@Component({
    selector: 'app-check-session',
    templateUrl: './check-session.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckSessionComponent implements OnInit {
    constructor(private router: Router) { }

    public authenticationLogin = environment.authenticationSettings.authorityUri;
    public oldValue: boolean = false;
    public newValue: boolean = false;

    public ngOnInit(): void {
        const url = this.router.url;
        const valueFromStorage = window.localStorage.getItem('shouldMonitorSession');
        const valueSet = valueFromStorage !== null && valueFromStorage !== undefined;

        if (valueSet) {
            window.localStorage.removeItem('shouldMonitorSession');
            this.oldValue = false;
            this.newValue = true;
        } else {
            this.oldValue = true;
            this.newValue = false;

            window.localStorage.setItem('shouldMonitorSession', 'false');
        }

        console.log('current val', window.localStorage.getItem('shouldMonitorSession'));
    }
}
