import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { SignInService } from '../sign-in.service';
import { UserClockSkewService } from '../user-clock-skew.service';
import { UserService } from '../user.service';

/**
 * This component is simply a placeholder while the user waits for
 * the browser to redirect to the authentication login page
 */
@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent implements OnInit, OnDestroy {
    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
        private signInService: SignInService,
        private router: Router,
        private readonly clockSkewService: UserClockSkewService) { }

    public authenticationLogin = environment.authenticationSettings.authorityUri;
    public prompt = 'Hi there. Please wait while we try and sign you in…';
    public showLink = false;

    public showSystemTimeWarning = false;
    private _isAuthenticatedSubscription: Subscription;

    public ngOnInit(): void {
        const url = this.router.url;

        if (url.indexOf('access_denied') >= 0) {
            this.handleAccessDeniedError();
            return;
        }

        this.signInService.handleSignin(url)
            .catch(() => {
                this._isAuthenticatedSubscription = this.userService.isAuthenticated
                    .subscribe((isAuthenticated) => {
                        if (isAuthenticated) {
                            setTimeout(() => {
                                window.location.replace(environment.applicationUrls.angularSafariRepro);
                            }, 5000);
                        } else {
                            this.handleError();
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this._isAuthenticatedSubscription) {
            this._isAuthenticatedSubscription.unsubscribe();
        }
    }

    private handleError(): void {
        this.prompt =
            'We have been unable to log you in at this time, please try again.';
        this.showLink = true;
        this.clockSkewService.getIsClockCorrect().then(isCorrect => {
            this.showSystemTimeWarning = !isCorrect;
            this.changeDetectorRef.detectChanges();
        });
        this.changeDetectorRef.detectChanges();
    }

    private handleAccessDeniedError(): void {
        this.prompt = 'You do not have permission to access this application';
        this.showLink = true;
        this.changeDetectorRef.detectChanges();
    }
}
