import { Injectable } from '@angular/core';

const returnUrlKey = 'return.url';

@Injectable({
    providedIn: 'root'
})
export class ReturnUrlService {
    get url(): string {
        return sessionStorage.getItem(returnUrlKey) || '';
    }

    set url(value: string) {
        sessionStorage.setItem(returnUrlKey, value);
    }

    public clear(): void {
        sessionStorage.removeItem(returnUrlKey);
    }
}
