import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-signed-out',
    templateUrl: './signed-out.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignedOutComponent {
    constructor() {
    }
}
