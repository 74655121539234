import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AUTH_HTTP_INTERCEPTOR_PROVIDER } from './auth-http-interceptor-provider';
import { CheckSessionComponent } from './check-session/check-session.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignedOutComponent } from './sign-out/signed-out.component';
import { USER_MANAGER_PROVIDER } from './user-manager.provider';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [SignInComponent, SignedOutComponent, CheckSessionComponent],
    providers: [
        USER_MANAGER_PROVIDER,
        AUTH_HTTP_INTERCEPTOR_PROVIDER
    ],
    exports: [
        CommonModule,
        RouterModule,
        CheckSessionComponent,
        SignInComponent,
        SignedOutComponent
    ]
})
export class AuthModule { }
