import { Injectable } from '@angular/core';
import { IApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService {

    constructor() { }

    private _appInsights: IApplicationInsights;

    public trackEvent(event: IEventTelemetry): void {
        if (!this._appInsights) {
            if (window && (window as any).appInsights) {
                this._appInsights = (window as any).appInsights as IApplicationInsights;
            }
        }

        if (this._appInsights && event) {
            this._appInsights.trackEvent(event);
        }
    }
}
