import { NgModule } from '@angular/core';

import { ApiService } from './api.service';
import { DateAgoPipe } from './date-ago.pipe';

@NgModule({
  providers: [
    ApiService,
  ],
  declarations: [
    // DateAgoPipe
  ]
})
export class SharedModule { }
