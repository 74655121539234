import { Environment } from './environment.interface';

export const environment: Environment = {
    production: true,
    apiRootUri: '/api',
    authenticationSettings: {
        authorityUri: 'https://apps-dev.enable.com',
        clientId: '0f8fad5b-d9cb-469f-a165-70867728950e',
        redirectUri: 'https://angular-safari-repro.enable.com/sign-in-oidc',
        postLogoutRedirectUri: 'https://angular-safari-repro.enable.com/sign-out-oidc',
        scopes: 'openid angularsafari',
        responseTypes: 'code',
        enableAutomaticSilentRenew: 'true',
        silentRedirectUri: 'https://angular-safari-repro.enable.com/silent-sign-in-oidc.html'
    },
    applicationUrls: {
        apps: 'https://apps-dev.enable.com',
        angularSafariRepro: 'https://angular-safari-repro.enable.com'
    },
};
