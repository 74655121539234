import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { WindowRef } from '../shared/window-ref.service';
import { User, UserManager } from 'oidc-client';
import { BehaviorSubject, Observable, Subject, Subscription, defer, of, throwError, timer, race } from 'rxjs';
import { publishReplay, refCount, take, mergeMap, filter, catchError, map, switchMap } from 'rxjs/operators';
import { ReturnUrlService } from './return-url.service';

const USER_TYPE_CLAIM = 'http://apps.enable.com/claims/user-type';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private readonly _userManager: UserManager,
        private readonly _returnUrlService: ReturnUrlService,
        private readonly _router: Router,
        private readonly _windowRef: WindowRef,
        private readonly _http: HttpClient
    ) {
        this._isAuthenticated$ = new Subject<boolean>();
        this._internalUserClaim$ = new BehaviorSubject<boolean | undefined>(undefined);

        // Use publishReplay and refCount to cache value once it is determined.
        this.isAuthenticated = this._isAuthenticated$.asObservable().pipe(
            publishReplay(1),
            refCount()
        );

        // Avoid registering the event handlers when silent-sign-in-oidc route is loaded in the IFRAME
        if (this._windowRef.nativeWindow.self === _windowRef.nativeWindow.top) {
            this.registerEventListeners();
            this.getUser().then(user => this.updateUserState(user));
        }
    }

    public readonly isAuthenticated: Observable<boolean>;

    // These normally won't be exposed from a service like this, but
    // for debugging it makes sense.
    async accessToken() { return await this.getUser().then(user => user ? user.access_token : ''); }
    async refreshToken() { return await this.getUser().then(user => user ? user.refresh_token: ''); }
    async identityClaims() { return await this.getUser().then(user => user ? user.profile: ''); }
    async idToken() { return await this.getUser().then(user => user ? user.id_token : ''); }
    async hasValidToken() { return await this.getUser().then(user => user ? !user.expired : false); }

    // Used by the embedded notifications component
    public get isAuthenticated$(): Observable<boolean> {
        return this.isAuthenticated;
    }

    public get internalUserClaimChanges(): Observable<boolean> {
        return this._internalUserClaim$.pipe(filter(o => o !== undefined));
    }

    // Use Subject (rather than BehaviorSubject) in order to block
    // until we determine whether user is authenticated.
    private readonly _isAuthenticated$: Subject<boolean>;

    private readonly _internalUserClaim$: BehaviorSubject<boolean | undefined>;

    public getUser(): Promise<User> {
        return this._userManager.getUser();
    }

    public getTimezone(): Promise<string> {
        return this.getUser().then((user) => {
            if (!user) {
                return null;
            }

            const ianaTimezone = user.profile['http://apps.enable.com/claims/iana-timezone'];

            return ianaTimezone;
        });
    }

    // Used by the embedded notifications component
    public getUserAccessToken(): Promise<string> {
        return this.getUser()
            .then(user => user.access_token);
    }

    public signOut(): Promise<any> {
        return this._userManager.signoutRedirect();
    }

    public signInSilentWithDelay(): void {
        setTimeout(() => {
            if (navigator.onLine) {
                this.signInSilent();
            } else {
                this.signInSilentWithDelay();
            }
        }, 1000);
    }

    public signInSilent(): void {
        this._userManager.signinSilent().catch(() => this.signInRedirectIfRequired());
    }

    public signInRedirectIfRequired(): void {
        // Don't redirect to sign-in if we're already on one of the OIDC URLs
        // and not within the app itself.
        if (this._router.url.indexOf('oidc') === -1) {
            this._returnUrlService.url = this._router.url;
            this._userManager.signinRedirect();
        }
    }

    private registerEventListeners(): void {
        this._userManager.events.addUserLoaded(user => this.updateUserState(user));

        this._userManager.events.addUserUnloaded(() => this.updateUserState());

        this._userManager.events.addAccessTokenExpired(() => {
            this.updateUserState();

            // Only attempt to do silent sign-in if the user has internet access
            if (navigator.onLine) {
                this.signInSilent();
            } else {
                this.signInSilentWithDelay();
            }
        });

        this._userManager.events.addSilentRenewError(() => {
            this.updateUserState();
            this.signInRedirectIfRequired();
        });

        this._userManager.events.addUserSignedOut(() => {
            // Even though user has signed out, the local user object / token
            // might not have expired, so remove it from storage.
            this._userManager.removeUser();
            this.updateUserState();

            this.signInRedirectIfRequired();
        });
    }

    private updateUserState(user?: User): void {
        this._isAuthenticated$.next(user && !user.expired);

        // Only fire if we have the information available
        if (!!user) {
            this._internalUserClaim$.next(user.profile[USER_TYPE_CLAIM] === 'Internal');
        }
    }
}
