import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, defer, from } from 'rxjs';
import { UserService } from './user.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthHttpInterceptor implements HttpInterceptor {
    constructor(private readonly _userService: UserService) { }

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return defer(() => {
            return from(this._userService.getUser())
                .pipe(
                    mergeMap(user => {
                        if (user && !user.expired) {
                            request = request.clone({
                                setHeaders: {
                                    Authorization: `${user.token_type} ${user.access_token}`
                                }
                            });
                        }

                        return next.handle(request);
                    })
                );
        });
    }
}
