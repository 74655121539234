import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  transform(secondsAgo: any, args?: any): any {
    if (secondsAgo) {
        // @ts-ignore
        const counter = Math.floor(secondsAgo);
        if (counter === 1) {
            return `${counter} second ago`;
        } else {
            return `${counter} seconds ago`;
        }
    }

    return 0;
  }
}
