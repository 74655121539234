/* eslint-disable brace-style */
/* eslint-disable max-len */

import { JsonPipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Observable, Subject } from 'rxjs';
import { count, map, startWith, takeUntil } from 'rxjs/operators';

import { UserService } from './auth/user.service';

@Component({
  selector: 'app-root',
  template: `<div class="container-fluid">
    <app-menu></app-menu>
    <div class="container-fluid mt-2">
      <h1>Welcome</h1>
      <p>This is part of the app.component. Below is the router outlet.</p>
      <hr>
      <router-outlet></router-outlet>
      <hr>
      <p>You can <a routerLink="/url-without-route">go to a url without a route</a> to see the fallback route.</p>
      <hr>
      <p>
        <button class="btn btn-success mr-1" (click)='login()'>login</button>
        <button class="btn btn-primary mr-1" (click)='logout()'>logout</button>
      </p>
      <p>
        <button class="btn btn-warning mr-1" (click)='refresh()'>force silent refresh/login</button>
        <button class="btn btn-secondary mr-1" (click)='reload()'>reload page</button>
        <button class="btn btn-danger mr-1" (click)='clearStorage()'>clear storage</button>
      </p>
      <hr>
      <table class="table table-bordered table-sm table-props">
        <tr><th>IsAuthenticated</th><td><code id="isAuthenticated">{{isAuthenticated$ | async}}</code></td></tr>
        <tr><th>HasValidToken</th><td><code id="hasValidToken">{{hasValidToken}}</code></td></tr>
        <tr><th>CanActivateProtectedRoutes</th><td><code id="canActivateProtectedRoutes">{{isAuthenticated$ | async}}</code></td></tr>
        <tr><th>Number of iframes</th><td><code id="numberOfIframes">{{numberOfIframes$ | async}}</code></td></tr>
        <tr><th>Last valid token generated at</th><td><code id="lastValidTokenGeneratedAt">{{lastValidTokenGeneratedAt$ | async | date: 'h:mm:ss a'}} ({{ lastValidTokenGeneratedAgo$ | async | dateAgo }})</code></td></tr>
        <tr><th>Page first loaded</th><td><code id="pageFirstLoaded">{{pageFirstLoaded | date: 'h:mm:ss a'}}</code></td></tr>
        <tr><th>Total successful silent sign in requests</th><td><code id="totalSuccessfulSilentSignInRequests">{{totalSuccessfulSilentSignInRequests$ | async}}</code></td></tr>
        <tr><th>IdentityClaims</th><td class="pre"><code id="identityClaims">{{identityClaims}}</code></td></tr>
        <tr><th>RefreshToken</th><td><code class="break-all">{{refreshToken}}</code></td></tr>
        <tr><th>AccessToken</th><td><code class="break-all">{{accessToken}}</code></td></tr>
        <tr><th>IdToken</th><td><code class="break-all">{{idToken}}</code></td></tr>
      </table>
    </div>
  </div>`,
})
export class AppComponent {
  isAuthenticated$: Observable<boolean>;
  numberOfIframes$: Observable<number>;
  lastValidTokenGeneratedAt$: Subject<Date> = new Subject<Date>();
  lastValidTokenGeneratedAgo$: Observable<number>;
  totalSuccessfulSilentSignInRequests$ = new BehaviorSubject<number>(0);
  destroy$ = new Subject();

  hasValidToken = false;
  accessToken = "";
  refreshToken = "";
  identityClaims = "";
  idToken = "";
  pageFirstLoaded: Date;

  constructor(
    private userService: UserService,
  ) {
    this.isAuthenticated$ = this.userService.isAuthenticated$;
  }

  ngOnInit() {
    this.isAuthenticated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
        this.refreshTable();
      });

    this.numberOfIframes$ = interval(100)
      .pipe(
        takeUntil(this.destroy$),
        map(() => document.getElementsByTagName('iframe').length)
      );

    const agoInterval = interval(1000)
      .pipe(
        takeUntil(this.destroy$)
      );

    this.lastValidTokenGeneratedAgo$ = combineLatest([agoInterval, this.lastValidTokenGeneratedAt$])
      .pipe(
        takeUntil(this.destroy$),
        map(x => x[1]),
        map((date) => {
          return (new Date().getTime() - date.getTime()) / 1000;
        })
      )

    this.pageFirstLoaded = new Date();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }

  login() { this.userService.signInRedirectIfRequired(); }
  logout() { this.userService.signOut(); }
  refresh() { this.userService.signInSilent(); }
  reload() { window.location.reload(); }
  clearStorage() { localStorage.clear(); }

  refreshTable() {
    this.userService.hasValidToken().then(valid => {
      this.hasValidToken = valid;
      if (!!valid) {
        this.lastValidTokenGeneratedAt$.next(new Date());
        this.totalSuccessfulSilentSignInRequests$.next(this.totalSuccessfulSilentSignInRequests$.value + 1);
      }
    });
    this.userService.accessToken().then(token => this.accessToken = token);
    this.userService.refreshToken().then(token => {
      this.refreshToken = token ? token : "";
      if (!!token) {
        // this.totalSuccessfulSilentSignInRequests$.next(this.totalSuccessfulSilentSignInRequests$.value + 1);
      }
    });
    this.userService.identityClaims().then(claims => this.identityClaims = claims ? new JsonPipe().transform(claims) : "");
    this.userService.idToken().then(token => this.idToken = token);
  }
}
