import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from '../services/app-insights.service';

@Injectable({
    providedIn: 'root'
})
export class UserClockSkewService {
    constructor(private readonly _appInsightsService: AppInsightsService) { }

    private readonly _clockSkew = 5 * 60 * 1000;
    private readonly _authorityUri = environment.authenticationSettings.authorityUri;
    private _prom: Promise<boolean> | null = null;

    public getIsClockCorrect(): Promise<boolean> {
        if (!this._prom) {
            this._prom = new Promise<boolean>((resolve, reject) => {
                const timeCheckUrl = this._authorityUri + '/api/auth/time';

                const xhr = new XMLHttpRequest();
                xhr.open('GET', timeCheckUrl, true);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState !== 4) {
                        return;
                    }

                    if (xhr.status !== 200 || !xhr.responseText || xhr.responseText === '') {
                        return reject(`Error getting server time from ${timeCheckUrl}`);
                    }

                    const serverTime = new Date(JSON.parse(xhr.responseText));
                    const isCorrect = this.isClientTimeWithinTolerance(serverTime);

                    if (!isCorrect) {
                        this.logClockIncorrectness();
                    }

                    return resolve(isCorrect);
                };

                xhr.send();
            });
        }

        return this._prom;
    }

    private isClientTimeWithinTolerance(serverTime: Date): boolean {
        // OIDC Client uses a default clock skew of 5 minutes. If we were to customise this,
        // we would put it in environment.authenticationSettings and then reference that here.
        const clientTime = new Date().getTime();
        return Math.abs(serverTime.getTime() - clientTime) < this._clockSkew;
    }

    private logClockIncorrectness(): void {
        const event = {
            name: 'incorrectUserClock',
            properties: {
                'clientTime': `${new Date()}`
            }
        } as IEventTelemetry;

        this._appInsightsService.trackEvent(event);
    }
}
